<script lang="ts" setup>
import { computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { queryGenerateWrittenContentWithAI } from "@/api/queries/writtenContent";
import {
  Nullable,
  IdType,
  WrittenContent,
  WrittenContentAITask,
  WrittenContentAITaskSuccessPayload,
} from "@/modules/types";
import { writtenContentRoutesNames } from "@/modules/writtenContent/routes";
import { useWrittenContentStore } from "@/modules/writtenContent/store";
import { useSnackbar } from "@/shared/hooks/useSnackbar";

const task = defineModel<WrittenContentAITask>("taskItem");
const dialogVisibleMV = defineModel("dialogVisible");

const props = defineProps<{
  dialogVisible?: boolean;
  position: number;
  taskItem: WrittenContentAITask;
  sklId: Nullable<IdType> | undefined;
  productRecommendationPath: Nullable<string> | undefined;
  writtenContentData: WrittenContent;
}>();

defineEmits([
  "update:dialogVisible",
  "aiWrittenContentGenerated",
  "update:taskItem",
]);

const writtenContentStore = useWrittenContentStore();

const router = useRouter();
const postData = computed(() => {
  return {
    entity_id: props.taskItem.writtenContentId,
    ref: router.resolve({
      name: writtenContentRoutesNames.adaptations(),
      params: {
        productId: props.taskItem.productId,
        writtenContentLocalizationId: props.taskItem.writtenContentParentId,
        writtenContentAdaptationId: props.taskItem.writtenContentId,
      },
    }).href,
    type: "written_content",
  };
});

const { mutate: generateWrittenContent } = queryGenerateWrittenContentWithAI(
  postData,
  task.value!.writtenContentId,
);

const itemName = computed(() => {
  return (
    props.taskItem.productName + " " + props.taskItem.writtenContentData.name
  );
});

const { pushNotification } = useSnackbar();
writtenContentStore.$onAction(({ name, args }) => {
  const [{ writtenContentId, msg }, isSuccess] = args as [
    WrittenContentAITaskSuccessPayload,
    boolean,
  ];

  if (name === "setWrittenContentAITaskSuccess") {
    if (writtenContentId === task.value!.writtenContentId) {
      task.value!.isAiGenerationInProgress = false;
    }
    if (isSuccess) {
      pushNotification(
        "success",
        `Content “${itemName.value}” was successfully generated with AI.`,
        {
          timeout: -1,
        },
      );
      writtenContentStore.writtenContentUpdateByThor(
        task.value!.writtenContentId,
      );
      writtenContentStore.removeWrittenContentAITask(
        task.value!.writtenContentId,
      );
    } else {
      // take the first part of error message ending with a dot or a colon
      const parsedError = msg && msg.match(/.*?[.]/);

      pushNotification(
        "error",
        `An error occurred while generating content for “${itemName.value}” using AI. ${parsedError ?? ""}`,
        {
          timeout: -1,
        },
      );
      writtenContentStore.removeWrittenContentAITask(
        task.value!.writtenContentId,
      );
    }
  }
});

onMounted(() => {
  generateWrittenContent();
  task.value!.isAiGenerationInProgress = true;
});
</script>
<template>
  <EccaSnackbar
    v-model="dialogVisibleMV"
    :color="'info'"
    :prependIcon="'$StoreBuildingWarehouse'"
    class="processing-ai-snackbar pt-10"
    :style="`transform: translateY(${position * 120}px)`"
    minWidth="620"
  >
    <div class="ecca-snackbar__text font-weight-bold mb-3">
      Content for {{ itemName }} is being generated.
    </div>
    <div>It can take up to 5 minutes.</div>
    <VProgressLinear
      class="processing-ai-snackbar__progress"
      indeterminate
      color="secondary"
    />
  </EccaSnackbar>
</template>

<style scoped>
.processing-ai-snackbar__progress {
  width: 100%;
  margin: 10px 0;
}
</style>
