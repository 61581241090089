<script setup lang="ts">
import { useAppStore } from "@/store/app";

const { notifications } = useAppStore();

const removeSnackbar = useAppStore().removeNotifications;
</script>

<template>
  <EccaSnackbar
    v-for="(snackbar, index) in notifications.filter((s: any) => s.visible)"
    :key="index"
    v-model="snackbar.visible"
    :color="snackbar.type"
    :prependIcon="snackbar.icon"
    class="pt-10"
    :style="`transform: translateY(${index * 60}px)`"
    :timeout="snackbar.timeout"
    :closeIcon="snackbar.closeIcon"
    :closeOnBack="false"
    :transition="false"
    @update:modelValue="removeSnackbar"
  >
    <div class="ecca-snackbar__text" v-html="snackbar.text"></div>
  </EccaSnackbar>
</template>

<style lang="scss">
.ecca-snackbar__text {
  a {
    color: inherit;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
</style>
