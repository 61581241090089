import { defineStore } from "pinia";
import { ComputedRef } from "vue";
import { ActionTypes } from "@/modules/templates/types";
import {
  IdType,
  IIntentPhase,
  IKeyword,
  WrittenContent,
  WrittenContentAITask,
  WrittenContentAITaskSuccessPayload,
} from "@/modules/types";
import { IKeywordsCollection } from "@/modules/writtenContent/components/WrittenContentEditor/types";

export type DialogModes = `create` | `edit`;

export type DialogStatusType = {
  delete: boolean;
  details: {
    status: boolean;
    mode?: DialogModes;
  };
  createNewAdaptation: boolean;
  createNewLocalization: boolean;
  editLocalization: boolean;
  editAdaptation: boolean;
  sklTable: boolean;
  createNewSkl: boolean;
  createNewReco: boolean;
  aiGeneration: boolean;
  aiProcess: boolean;
  aiConfirmCancel: boolean;
  deleteStrategyRecommendationFile: boolean;
  uploadStrategyRecommendation: boolean;
  updateConfigFields: boolean;
};

export type WrittenContentWidgetSection =
  | "skl"
  | "guidance"
  | "reco"
  | undefined;

interface IProductsState {
  isSidePanelExpanded: boolean;
  aiTasks: WrittenContentAITask[];
  isAiGenerationOn: boolean;
  isAiGenerationInProgress: boolean;
  isAIGenerated: boolean;
  isAiGenerationPossible: boolean;
  isAiGenerationSuccess: boolean | null;
  isEditModeEnabled: boolean;
  isHighlightModeEnabled: boolean;
  activeElementId: IdType | null;
  activeElementName: string;
  dialogStatus: DialogStatusType;
  recommendationVisibility: boolean;
  dialogKey: number;
  dialogData: any;
  currentActionType?: ActionTypes;
  clickedElement?: WrittenContent;
  sklWidget: {
    isExpanded: boolean;
    dragSidePanel: boolean | null;
    widgetSection?: WrittenContentWidgetSection;
  };
  sklData: {
    raw: IKeyword[] | null;
    parsed: IKeywordsCollection[];
  };
  intentPhases: IIntentPhase[];
  intentPhasesToParse: IIntentPhase[];
  eCommercePerfectNameFields: eCommercePerfectNameFieldsType[];
}

export type eCommercePerfectNameFieldsType = {
  id: IdType;
  text: string;
  title: string;
  placeholder: string;
};

const initialState: IProductsState = {
  isSidePanelExpanded: true,
  aiTasks: [],
  isAiGenerationOn: true,
  isAIGenerated: false,
  isAiGenerationSuccess: null,
  isAiGenerationPossible: false,
  isAiGenerationInProgress: false,
  isEditModeEnabled: true,
  isHighlightModeEnabled: true,
  activeElementId: null,
  activeElementName: "",
  dialogKey: 0,
  recommendationVisibility: true,
  dialogStatus: {
    delete: false,
    details: {
      status: false,
    },
    createNewAdaptation: false,
    createNewLocalization: false,
    editLocalization: false,
    editAdaptation: false,
    sklTable: false,
    createNewSkl: false,
    createNewReco: false,
    aiGeneration: false,
    aiProcess: false,
    aiConfirmCancel: false,
    updateConfigFields: false,
    uploadStrategyRecommendation: false,
    deleteStrategyRecommendationFile: false,
  },
  dialogData: undefined,
  sklWidget: {
    isExpanded: false,
    dragSidePanel: null,
  },
  sklData: {
    raw: null,
    parsed: [],
  },
  intentPhases: [],
  intentPhasesToParse: [],
  eCommercePerfectNameFields: [
    {
      id: "",
      text: "",
      title: "",
      placeholder: "",
    },
  ],
};

export const useWrittenContentStore = defineStore("ecca-writtenContent", {
  state: (): IProductsState => ({ ...initialState }),
  getters: {
    getWrittenContentAITaskById: (state) => {
      return (writtenContentID: ComputedRef<string>) =>
        state.aiTasks.find((task) => {
          if (task.writtenContentId === writtenContentID.value) {
            return task;
          }
        });
    },
  },
  actions: {
    addWrittenContentAITask(item: WrittenContentAITask) {
      const task = { ...item, visible: true };

      this.aiTasks.push(task);
    },

    async setWrittenContentAITaskSuccess(
      payload: WrittenContentAITaskSuccessPayload,
      isSuccess: boolean,
    ) {
      return new Promise((resolve) => {
        const index = this.aiTasks.findIndex(
          (item: any) => item.writtenContentId === payload.writtenContentId,
        );
        if (index !== -1) {
          this.aiTasks[index].isAiGenerationSuccess = isSuccess;
        }
        resolve(true);
      });
    },

    removeWrittenContentAITask(writtenContentID: IdType) {
      const index = this.aiTasks.findIndex(
        (item: any) => item.writtenContentId === writtenContentID,
      );
      if (index !== -1) {
        this.aiTasks.splice(index, 1);
      }
    },

    toggleSidePanel() {
      this.isSidePanelExpanded = !this.isSidePanelExpanded;
      if (this.sklWidget.isExpanded) {
        this.resetSklWidgetState();
      }
    },
    toggleSklWidget() {
      if (this.sklWidget.dragSidePanel === null)
        this.sklWidget.dragSidePanel = this.isSidePanelExpanded;

      this.sklWidget.isExpanded = !this.sklWidget.isExpanded;

      if (this.sklWidget.dragSidePanel)
        this.isSidePanelExpanded = !this.sklWidget.isExpanded;
    },
    hideWrittenContentRecommendations() {},
    showWrittenContentRecommendations() {},
    enableAIGenerationButton() {
      this.isAiGenerationPossible = true;
    },
    disableAIGenerationButton() {
      this.isAiGenerationPossible = false;
    },
    turnOffAIGenerationButton() {
      this.isAiGenerationOn = false;
    },
    turnOnAIGenerationButton() {
      this.isAiGenerationOn = true;
    },
    setWCGeneratedWithAi(isAIGenerated: boolean) {
      this.isAIGenerated = isAIGenerated;
    },
    resetSidePanelState() {
      this.isSidePanelExpanded = true;
    },
    resetSklWidgetState() {
      this.sklWidget.isExpanded = false;
      this.sklWidget.dragSidePanel = null;
    },
    toggleHighlightMode() {
      this.isHighlightModeEnabled = !this.isHighlightModeEnabled;
    },
    setEditMode(value: boolean) {
      this.isEditModeEnabled = value;
    },
    setActiveWrittenContentElement(id: IdType) {
      this.activeElementId = id;
    },
    resetActiveWrittenContentElement() {
      this.activeElementId = null;
    },
    resetAdapterCreatorDialogStatus() {
      this.dialogStatus = {
        ...initialState.dialogStatus,
      };
    },
    resetWrittenContentDialogStatus() {
      this.dialogKey++;
      this.dialogStatus = {
        ...initialState.dialogStatus,
      };
      this.dialogData = initialState.dialogData;
    },
    saveWrittenContent() {},
    exportWrittenContent() {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    writtenContentUpdateByThor(writtenContentId: IdType) {},
    setECommercePerfectNameFields(fields: eCommercePerfectNameFieldsType[]) {
      this.eCommercePerfectNameFields = fields;
    },
    openModal({
      type,
      item,
      actionType,
      mode,
      payload,
    }: {
      type: keyof DialogStatusType;
      item?: WrittenContent;
      actionType?: ActionTypes;
      mode?: `create` | `edit`;
      payload?: any;
    }) {
      this.resetWrittenContentDialogStatus();
      this.currentActionType = actionType;
      this.clickedElement = item;
      if (payload) this.dialogData = payload;

      if (type === "details") {
        this.dialogStatus[type].status = true;
        if (mode) this.dialogStatus[type].mode = mode;
      } else {
        this.dialogStatus[type] = true;
      }
    },
  },
});
